import * as XLSX from "xlsx";
export const onGenerateExcel = (data, name) => {
    let ws = XLSX.utils.aoa_to_sheet(data); // 加到sheet 里面

    ws['!cols'] = [{ wch: 20 }, { wch: 20 }]; // 设置每列宽度
    /* generate workbook and add the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // sheet 加到文件里面

    let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }); //转换为二进制

    // 定义 应该二进制转 blob 的方法
    const s2ab = (s) => {
        let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        let view = new Uint8Array(buf); //create uint8array as viewer
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
        return buf;
    };

    // 创建一个a 标签 好下载
    const fileURL = window.URL.createObjectURL(
        new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
    );
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${name}.xlsx`);
    document.body.appendChild(fileLink);

    fileLink.click();
}
