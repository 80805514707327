<template>
  <el-dialog
    title="物流信息"
    v-model="dialogVisible"
    width="600px"
    @close="handleClose"
  >
    <div class="logistics-info" v-loading="loading">
      <el-timeline v-if="logisticsList.length">
        <el-timeline-item
          v-for="(item, index) in logisticsList"
          :key="index"
          :timestamp="item.time"
          placement="top"
        >
          <div class="logistics-content">{{ item.context }}</div>
        </el-timeline-item>
      </el-timeline>
      <el-empty v-else description="暂无物流信息" />
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { ElMessage } from 'element-plus'
import service from '../service'

const dialogVisible = ref(false)
const loading = ref(false)
const logisticsList = ref([])

// 统一的弹窗打开方法
const openDialog = async (orderId) => {
  dialogVisible.value = true
  // 使用 nextTick 确保弹窗显示后再加载数据
  await nextTick()
  // 重置数据
  logisticsList.value = []
  // 加载物流信息
  await getLogisticsInfo(orderId)
}

const getLogisticsInfo = async (orderId) => {
  loading.value = true
  try {
    const res = await service.getPrizesDeliverExpress({
      order_id: orderId
    })
    logisticsList.value = res.data || []
  } catch (error) {
    console.error(error)
    ElMessage.error('获取物流信息失败')
  } finally {
    loading.value = false
  }
}

const handleClose = () => {
  dialogVisible.value = false
  logisticsList.value = []
}

// 暴露方法给父组件
defineExpose({
  openDialog
})
</script>

<style scoped>
.logistics-info {
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
}

.logistics-content {
  font-size: 14px;
  color: #606266;
  line-height: 1.5;
}
</style> 