<template>
  <el-dialog
    v-model="dialogVisible"
    title="追加数量"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="append-num-content">
      <el-form :model="form" label-position="left" label-width="80px" ref="formRef" :rules="rules" @submit.prevent>
        <el-form-item label="追加数量" required>
          <el-input-number
            v-model="form.num"
            :min="0"
            :max="9999999"
            :step="1"
            :precision="0"
            controls-position="right"
            style="width: 100%"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" :loading="loading" @click="handleConfirm">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'

// 响应式状态
const dialogVisible = ref(false)
const loading = ref(false)
const formRef = ref(null)
const form = reactive({
  prize_id: '',
  num: 1
})

// 方法定义
const emit = defineEmits(['before-open', 'after-open', 'cancel', 'confirm', 'change'])

// 表单验证
const rules = {
  num: [{ type: 'number', message: '请输入数字', trigger: 'blur', min: 1 }]
}


// 打开弹窗方法
const openDialog = async (prizeId) => {
  // 触发打开前钩子
  
  // 初始化表单数据
  form.prize_id = prizeId
  form.num = 0
  loading.value = false
  
  // 显示弹窗
  dialogVisible.value = true
  
  // 触发打开后钩子
  await nextTick()
  emit('after-open', { form })
}

// 取消按钮处理
const handleCancel = () => {
  dialogVisible.value = false
  emit('cancel')
}

// 确认按钮处理
const handleConfirm = async () => {
  formRef.value.validate((valid) => {
    if (valid) {
      loading.value = true
      emit('change', form.num)
      dialogVisible.value = false
    }
  })
}

// 暴露方法给父组件使用
defineExpose({
  openDialog
})
</script>

<style scoped>
.append-num-content {
  padding: 20px 20px 0;
}
</style>