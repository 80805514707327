import Layouts from '@/components/layouts/LayoutsView.vue';


export default [
    {
        path: '/liveManagement',
        name: '直播管理',
        component: Layouts,
        meta: {
            title: '直播管理'
        },
        children: [
            {
                path: 'liveList',
                name: '直播列表',
                meta: {
                    title: '直播列表'
                },
                component: () => import('@/views/obsLiveManage/ListView.vue')
            },
        ]
    }
]