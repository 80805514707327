<script setup lang="ts">
// 将el-table的属性全部放到一个属性中通过v-bind传递给el-table
// 将data通过属性传递给el-table，不再使用传递请求方法的方式进行异步请求，而是在外部请求数据后传递给el-table，对外会有方法控制表格的刷新
// 会有一个描述表格列的配置数组，用于生成表格列，需要插槽生成表格列的内容，需要使用动态插槽进行处理
import { defineProps, computed, ref, defineExpose, nextTick } from 'vue'
import { Setting } from '@element-plus/icons-vue'
import SettingPanel from '@/components/table/components/settingPanelV2/SettingPanel.vue'
import { onGenerateExcel } from '@/assets/common/generateExcelTool'
import { useRoute } from 'vue-router'

const route = useRoute()

const settingPanelRef = ref(null)

interface ColumnConfig {
  label: string;
  prop?: string;
  exportOnly?: boolean;
  exportFormatter?: (row: any) => any;
  formatter?: (row: any) => any;
  slot?: string;
  type?: string;
  width?: string;
  align?: string;
  headerAlign?: string;
}

const props = defineProps({
  columns: {
    type: Array as () => ColumnConfig[],
    required: true
  },
  data: Array,
  config: {
    type: Object,
    default: () => ({})
  },
  total: {
    type: Number,
    default: 0
  },
  isSelection: {
    type: Boolean,
    default: false
  },
  selectionFilterFn: {
    type: Function,
    default: () => false
  },
  loading: {
    type: Boolean,
    default: false
  },
  page: {
    type: Number,
    default: 1
  },
  pageSize: {
    type: Number,
    default: 10
  },
  getExportData: {
    type: Function,
    default: () => ({})
  },
  exportName: {
    type: String,
    default: '导出列表'
  },
  isIndex: {
    type: Boolean,
    default: true
  },
  isBottom: {
    type: Boolean,
    default: true
  },
  isTop: {
    type: Boolean,
    default: true
  },
  isRadio: {
    type: Boolean,
    default: false
  },
  isSetting: {
    type: Boolean,
    default: false
  }
})

const tableRef = ref(null)
const currentRow = ref(null)
const currentHoverRow = ref(null)

const emits = defineEmits([
  'update:page',
  'update:pageSize',
  'getList',
  'getRows',
  'current-change',
  'sortChange'
])

// 过滤掉仅导出的列，用于表格显示
const displayColumns = computed(() => {
  return props.columns.filter(col => !col.exportOnly)
})

const columnsSetting = ref(
  localStorage.getItem(route.path + 'columns')
    ? JSON.parse(localStorage.getItem(route.path + 'columns'))
    : displayColumns.value.map((item) => item.label)
)

const columnsFormat = computed(() => {
  return columnsSetting.value.map((item) => {
    return displayColumns.value.find((column) => column.label === item)
  })
})

const exportLoading = ref(false)
const selectedRows = ref([])

// 格式化表格列
const formatColumns = computed(() => {
  const selectionRow = {
    type: 'selection',
    'reserve-selection': true,
    selectable: (row: any, index: number) => {
      return props.selectionFilterFn(row, index)
    }
  }
  const indexRow = {
    type: 'index',
    index: (index: number) => {
      return (props.page - 1) * props.pageSize + index + 1
    },
    label: '序号',
    width: '80px',
    headerAlign: 'center',
    align: 'center'
  }
  const arr = [...columnsFormat.value]
  props.isIndex && arr.unshift(indexRow)
  props.isSelection && arr.unshift(selectionRow)
  return arr
})

const handleSelectionChange = (rows: any[]) => {
  selectedRows.value = rows
  emits('getRows', rows)
}

const handleSortChange = (rows: any) => {
  emits('sortChange', rows)
}

const clearSelection = () => {
  tableRef.value?.clearSelection()
}

const getSelectedRows = () => {
  return selectedRows.value
}

const onPageChange = (page: number) => {
  emits('update:page', page)
  emits('getList')
}

const onPageSizeChange = (pageSize: number) => {
  emits('update:page', 1)
  emits('update:pageSize', pageSize)
  emits('getList')
}

// 处理导出 - 支持exportOnly的列
const handleExport = async () => {
  if (exportLoading.value) return
  exportLoading.value = true
  const res = await props.getExportData().finally(() => {
    exportLoading.value = false
  })

  // 获取导出列配置，包括exportOnly的列
  const exportableColumns = props.columns.filter(col => !col.slot)
  const list = localStorage.getItem(route.path + 'export')
    ? JSON.parse(localStorage.getItem(route.path + 'export')).checkList
    : exportableColumns.map((item) => item.label)

  const headArr = list.map((item) => {
    return exportableColumns.find((column) => column.label === item)?.label
  })
  const handleheadArr = headArr.filter((item) => item !== '操作')
  const dataList = [handleheadArr]

  res.forEach((row) => {
    const arr = []
    list.forEach((item) => {
      const column = exportableColumns.find((col) => col.label === item)
      if (column) {
        const formatter = column.exportFormatter || column.formatter
        const value = formatter ? formatter(row) : row[column.prop]
        arr.push(value)
      }
    })
    dataList.push(arr)
  })

  onGenerateExcel(
    dataList,
    localStorage.getItem(route.path + 'export')
      ? JSON.parse(localStorage.getItem(route.path + 'export')).name
      : props.exportName
  )
}

const openSettingPanel = () => {
  settingPanelRef.value?.openDialog()
}

const onChangeColumns = (columns: string[]) => {
  columnsSetting.value = columns
}

const handleCurrentChange = (row: any) => {
  currentRow.value = row
  emits('current-change', row)
}

const setCurrentRow = (row: any) => {
  const localRow = props.data?.find((item: any) => item.id === row.id)
  tableRef.value?.setCurrentRow(localRow)
}

const setSelection = (rows: any[] = []) => {
  tableRef.value?.clearSelection()
  selectedRows.value = []
  nextTick(() => {
    rows.forEach((row) => {
      const localRow = props.data?.find((item: any) => item.id === row.id)
      tableRef.value?.toggleRowSelection(localRow || row, true)
    })
  })
}

const getCurrentRow = () => {
  return currentRow.value
}

const handleCellMouseEnter = (row: any) => {
  currentHoverRow.value = row
}

const handleCellMouseLeave = (row: any) => {
  currentHoverRow.value = null
}

defineExpose({
  clearSelection,
  getSelectedRows,
  setCurrentRow,
  getCurrentRow,
  currentRow,
  setSelection,
  currentHoverRow
})
</script>

<template>
  <el-card shadow="never">
    <el-row justify="space-between" v-if="isTop" class="py-2">
      <el-col :span="12"><slot name="startActive"></slot></el-col>
      <el-col :span="12">
        <el-row justify="end" v-if="isTop">
          <slot name="topActive"></slot>
          <el-button @click="handleExport" :loading="exportLoading">导出</el-button>
          <el-button text :icon="Setting" @click="openSettingPanel" v-if="isSetting"></el-button>
        </el-row>
      </el-col>
    </el-row>

    <el-row justify="start"></el-row>
    <el-table
      @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave"
      v-bind="config"
      :data="data"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
      ref="tableRef"
      v-loading="loading"
      :highlight-current-row="isRadio"
      :header-cell-style="{
        color: '#555555',
        fontWeight: 400,
        fontSize: '14px'
      }"
      @current-change="handleCurrentChange"
    >
      <el-table-column v-for="column in formatColumns" :key="column.prop" v-bind="column">
        <template v-if="column.slot" v-slot="scope">
          <slot :name="column.slot" v-bind="scope"></slot>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty description="暂无数据" />
      </template>
    </el-table>
    <el-row justify="center" class="bottom-area py-2" v-if="isBottom && total">
      <div class="statistical-area">
        <span class="primary-color" v-if="isSelection">已选{{ selectedRows.length }}条，</span>
        <span class="primary-color">共{{ total }}条</span>
      </div>
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :current-page="page"
        @current-change="onPageChange"
        @size-change="onPageSizeChange"
        layout="sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-row>
    <SettingPanel
      ref="settingPanelRef"
      :columns="displayColumns"
      :export-name="exportName"
      @change-columns="onChangeColumns"
    ></SettingPanel>
  </el-card>
</template>

<style scoped>
.bottom-area {
  position: relative;
  width: 100%;
  background-color: var(--bg-color);
}
.statistical-area {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.primary-color {
  color: var(--el-color-primary);
  font-size: 14px;
}
</style> 