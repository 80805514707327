import Layouts from '@/components/layouts/LayoutsView.vue'
import ActivityList from '@/views/springFestivalActivity/ActivityList.vue'
import PrizesList from '@/views/springFestivalActivity/PrizesList.vue'
import PrizeDistributed from '@/views/springFestivalActivity/PrizeDistributed.vue'


export default [
  {
    path: '/springFestival',
    name: '春节活动',
    component: Layouts,
    meta: {
      title: '春节活动'
    },
    children: [
      {
        path: 'activityList',
        name: '活动列表',
        meta: {
          title: '活动列表'
        },
        component: ActivityList
      },
      {
        path: 'prizesList',
        name: '奖品列表',
        meta: {
          title: '奖品列表'
        },
        hidden: true,
        component: PrizesList
      },
      {
        path: 'prizeDistributed',
        name: '奖品发放',
        meta: {
          title: '奖品发放'
        },
        component: PrizeDistributed
      }
    ]
  }
]
