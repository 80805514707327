<template>
  <el-dialog v-model="dialogVisible" title="确认报名" width="30%">
    <div class="p-4">
      <div class="text-base mb-4">报名信息</div>
      <el-input
        type="textarea" 
        v-model="form.remark"
        :rows="4"
        placeholder="请根据活动信息填写您的报名说明，方便客服审核！"
      />
      <div class="text-gray-400 text-sm mt-2">温馨提示：报名说明越详细越有助于快速通过审核哦~</div>
    </div>
    <template #footer>
      <div class="flex justify-center gap-4">
        <el-button type="primary" @click="handleConfirm" :loading="loading">确认</el-button>
        <el-button @click="handleCancel" :disabled="loading">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, h, computed } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { enrollActivity } from '@/model/notice/notice.js'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  activityId: {
    type: [String, Number],
    required: true
  }
})

const emit = defineEmits(['update:modelValue', 'success'])

// 弹窗显示状态
const dialogVisible = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
})

// 表单数据
const form = reactive({
  remark: '',
  id: props.activityId
})

// loading状态
const loading = ref(false)

// 确认报名
const handleConfirm = async () => {
  try {
    await ElMessageBox.confirm('报名后官方客服将联系您确认具体合作细节,请保持手机畅通！', '确认报名', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      message: h('div', { style: { textAlign: 'center', marginBottom: '16px' } }, [
        h('div', null, '是否确认报名活动？'),
        h('div', null, '报名后官方客服将联系您确认具体合作细节,请保持手机畅通！')
      ])
    })
    
    loading.value = true
    await enrollActivity({
      id: props.activityId,
      remark: form.remark
    }).then(res => {
      if (!res) return
      ElMessage.success('报名成功')
      emit('success')
      handleCancel()
    })
  } catch (error) {
    if (error !== 'cancel') {
      ElMessage.error('报名失败')
    }
  } finally {
    loading.value = false
  }
}

// 取消
const handleCancel = () => {
  form.remark = ''
  dialogVisible.value = false
}
</script> 