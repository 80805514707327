import Layouts from '@/components/layouts/LayoutsView.vue'
const groupList = () => import('@/views/groupManagement/groupList.vue')
const addGroup = () => import('@/views/groupManagement/addGroup.vue')
const vipGroupAdd = () => import('@/views/groupManagement/vipGroupAdd.vue')
const groupGiftOrder = () => import('@/views/groupManagement/groupGiftOrder.vue')
const SpecialGroupAdd = () => import('@/views/groupManagement/SpecialGroupAdd.vue')
const pokemonCardBase = () => import('@/views/groupManagement/pokemonCardBase.vue')
const deliverGoodsList = () => import('@/views/groupManagement/deliverGoodsList.vue')
const searchQuickSendDeliver = () => import('@/views/groupManagement/searchQuickSendDeliver.vue')
const userPickUpList = () => import('@/views/groupManagement/userPickUpList.vue')
const uploadReport = () => import('@/views/groupManagement/UploadReport.vue')
const newUploadReport = () => import('@/views/groupManagement/NewUploadReport.vue')

const seriesGroup = () => import('@/views/seriesFuBox/SeriesFuBox.vue')
const seriesLuckBoxTemplate = () =>
  import('@/views/seriesLuckBoxTemplate/seriesLuckBoxTemplate.vue')

const seriesLuckBoxNew = () => import('@/views/seriesFuBox/ListView.vue')
const seriesGroupDetail = () => import('@/views/seriesFuBox/components/LuckyBoxDetail.vue')
const luckyBoxGoodsDetail = () => import('@/views/seriesFuBox/components/LuckyBoxGoodsDetail.vue')

const luckBoxNewActivity = () => import('@/views/seriesFuBox/components/LuckBoxNewActivity.vue')

const luckBoxChangeSeries = () => import('@/views/seriesFuBox/components/LuckBoxChangeSeries.vue')

const luckyBoxNewSeries = () => import('@/views/seriesFuBox/components/LuckyBoxNewSeries.vue')

const opensurplusRang = () => import('@/views/groupManagement/OpenSurplusRang.vue')

const appendKamiTemplate = () => import('@/views/appendKamiTemplate/AppendKamiTemplate.vue')

export default [
  {
    path: '/group',
    name: '团购管理',
    component: Layouts,
    meta: {
      title: '团购管理'
    },
    children: [
      {
        path: 'groupList',
        name: '团购列表',
        meta: {
          title: '团购列表'
        },
        component: groupList
      },
      {
        path: 'addGroup',
        name: '添加团购',
        meta: {
          title: '添加团购'
        },
        hidden: true,
        component: addGroup
      },
      {
        path: 'specialGroupAdd',
        name: '特价盒子团购',
        meta: {
          title: '特价盒子团购'
        },
        hidden: true,
        component: SpecialGroupAdd
      },
      {
        path: 'uploadReport',
        name: '旧版上传报告',
        meta: {
          title: '旧版上传报告'
        },
        hidden: true,
        component: uploadReport
      },
      {
        path: 'newUploadReport',
        name: '新版上传报告',
        meta: {
          title: '新版上传报告'
        },
        hidden: true,
        component: newUploadReport
      },
      {
        path: 'vipGroupAdd',
        name: '添加专区团购',
        meta: {
          title: '添加专区团购'
        },
        hidden: true,
        component: vipGroupAdd
      },
      {
        path: 'groupGiftOrder',
        name: '团购赠品订单',
        meta: {
          title: '团购赠品订单'
        },
        component: groupGiftOrder
      },
      {
        path: 'pokemonCardBase',
        name: '宝可梦卡片库',
        meta: {
          title: '宝可梦卡片库'
        },
        component: pokemonCardBase
      },
      // {
      //   path: 'seriesGroup',
      //   name: '系列团购',
      //   meta: {
      //     title: '系列团购'
      //   },
      //   seriesHidden: true,
      //   component: seriesGroup
      // },
      {
        path: 'seriesGroupNew',
        name: '系列团购',
        meta: {
          title: '系列团购'
        },
        seriesHidden: true,
        component: seriesLuckBoxNew
      },
      {
        path: 'seriesGroupDetail',
        name: '系列团购详情',
        meta: {
          title: '系列团购详情'
        },
        hidden: true,
        component: seriesGroupDetail
      },
      {
        path: 'luckyBoxGoodsDetail',
        name: '系列团购商品详情',
        meta: {
          title: '系列团购商品详情'
        },
        hidden: true,
        component: luckyBoxGoodsDetail
      },
      {
        path: 'luckBoxNewActivity',
        name: '开启新活动',
        meta: {
          title: '开启新活动'
        },
        hidden: true,
        component: luckBoxNewActivity
      },
      {
        path: 'luckBoxChangeSeries',
        name: '修改系列团购',
        meta: {
          title: '修改系列团购'
        },
        hidden: true,
        component: luckBoxChangeSeries
      },
      {
        path: 'luckyBoxNewSeries',
        name: '新增系列',
        meta: {
          title: '新增系列'
        },
        hidden: true,
        component: luckyBoxNewSeries
      },
      {
        path: 'seriesLuckBoxTemplate',
        name: '自定义系列团购模板',
        meta: {
          title: '自定义系列团购模板'
        },
        seriesHidden: true,
        component: seriesLuckBoxTemplate
      },
      {
        path: 'deliverGoodsList',
        name: '合并发货',
        meta: {
          title: '合并发货'
        },
        hidden: true,
        component: deliverGoodsList
      },
      {
        path: 'searchQuickSendDeliver',
        name: '快递发货查询',
        meta: {
          title: '快递发货查询'
        },
        component: searchQuickSendDeliver
      },
      {
        path: 'userPickUpList',
        name: '用户自提',
        meta: {
          title: '用户自提'
        },
        component: userPickUpList
      },
      {
        path: 'surplusRang',
        name: '开启剩余随机',
        meta: {
          title: '开启剩余随机'
        },
        hidden: true,
        component: opensurplusRang
      },
      {
        path: 'appendKamiTemplate',
        name: '自定义追加卡密模板',
        meta: {
          title: '自定义追加卡密模板'
        },
        component: appendKamiTemplate
      }
    ]
  }
]
