<template>
  <el-container>
    <el-row justify="end">
        <el-button type="primary" @click="handleAdd">添加奖品</el-button>
        <el-button @click="handleBatchDelete" :disabled="!selectedRows.length">删除</el-button>  
    </el-row>

    <NbTableV2
      ref="tableRef"
      v-model:page="form.page"
      v-model:pageSize="form.pageSize"
      :loading="loading"
      :data="tableData"
      :total="total"
      :columns="columns"
      :isBottom="true"
      :isTop="false"
      :isSelection="true"
      :config="{
        border: true,
        rowKey: 'id'
      }"
      @getList="getList"
      @getRows="handleGetRows"
    >
      <template #type="{ row }">
        <small-dot-v2 :type="row.type === 1 ? 'success' : 'primary'" >{{ row.type === 1 ? '实物' : '优惠券' }}</small-dot-v2>
      </template>
      <!-- 奖品图片插槽 -->
      <template #image="{ row }">
        <el-image 
          :src="row.image" 
          fit="cover"
          style="width: 50px; height: 50px;"
        />
      </template>

      <!-- 操作插槽 -->
      <template #operate="{ row }">
        <el-button type="primary" link @click="handleEdit(row)">编辑</el-button>
        <el-button type="danger" v-if="isShowDelete" link @click="handleDelete(row)">删除</el-button>
      </template>
    </NbTableV2>
  </el-container>

  <AddPrize ref="addPrizeRef" @refresh="getList" />
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import NbTableV2 from '@/components/table/components/NbTableV2.vue'
import AddPrize from './components/AddPrize.vue'
import service from './service'
import { ElMessageBox, ElMessage } from 'element-plus'

const form = ref({
  activity_id: '',
  page: 1,
  pageSize: 20
})

const route = useRoute()
const router = useRouter()
console.log(route.query.status, 'status')
const isShowDelete = computed(() => {
  return route.query.status == 1
})

const tableRef = ref(null)
const tableData = ref([])
const loading = ref(false)
const total = ref(0)
const selectedRows = ref([])

const addPrizeRef = ref(null)

// 表格列配置
const columns = [
  {
    prop: 'name',
    label: '奖品名称',
    align: 'left',
    minWidth: '150',
    headerAlign: 'center'
  },
  {
    prop: 'type',
    label: '奖品类型',
    align: 'center',
    minWidth: '100',
    headerAlign: 'center',
    slot: 'type'
  },
  {
    label: '奖品图片',
    align: 'center',
    minWidth: '100',
    slot: 'image',
    headerAlign: 'center'
  },
  {
    prop: 'price',
    label: '参考价值',
    align: 'right',
    minWidth: '100',
    headerAlign: 'center'
  },
  {
    prop: 'num',
    label: '奖品数量',
    align: 'right',
    minWidth: '100',
    headerAlign: 'center'
  },
  {
    prop: 'has_num',
    label: '剩余数量',
    align: 'right',
    minWidth: '100',
    headerAlign: 'center'
  },
  {
    prop: 'luck_value',
    label: '兑换单价',
    align: 'right',
    minWidth: '100',
    headerAlign: 'center'
  },
  {
    prop: 'exchange_limit_quantity',
    label: '每人限兑次数',
    align: 'right',
    minWidth: '120',
    headerAlign: 'center'
  },
  {
    prop: 'sort',
    label: '排序',
    align: 'right',
    minWidth: '80',
    headerAlign: 'center'
  },
  {
    label: '操作',
    align: 'right',
    minWidth: '120',
    slot: 'operate',
    headerAlign: 'center',
    fixed: 'right'
  }
]

onMounted(() => {
  form.value.activity_id = route.query.activity_id
  getList()
})




// 获取列表数据
const getList = async () => {
  loading.value = true
  try {
    // TODO: 调用接口获取数据
    const res = await service.getPrizesList(form.value)
    tableData.value = res.list
    total.value = res.total
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

// 处理获取选中行
const handleGetRows = (rows) => {
  selectedRows.value = rows
}

// 添加奖品
const handleAdd = () => {
  // TODO: 实现添加奖品逻辑
  addPrizeRef.value.openDialog(route.query.activity_id)
}

// 编辑奖品
const handleEdit = (row) => {
  // TODO: 实现编辑奖品逻辑
  addPrizeRef.value.openDialog(route.query.activity_id, row.id)
}

// 删除奖品
const handleDelete = (row) => {
  ElMessageBox.confirm(`确认要删除【${row.name}】吗？`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(async () => {
    try {
      const res = await service.deletePrizes({ prize_id: row.id })
      if (!res) return
      ElMessage.success('操作成功')
      getList()
    } catch (error) {
      console.error(error)
      ElMessage.error('删除失败')
    }
  }).catch(() => {})
}

// 批量删除奖品
const handleBatchDelete = () => {
  ElMessageBox.confirm('确认要删除选中的奖品吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(async () => {
    try {
      const res = await service.batchDeletePrizes({ prizeIds: selectedRows.value.map(item => item.id).join(',') })
      if (!res) return
      ElMessage.success('操作成功')
      selectedRows.value = []
      tableRef.value.clearSelection()
      getList()
    } catch (error) {
      console.error(error)
      ElMessage.error('删除失败')
    }
  }).catch(() => {})
}

</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>