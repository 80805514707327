<template>
  <!-- 发货弹窗 -->
  <el-dialog
      title="发货"
      v-model="dialogVisible"
      width="500px"
    >
      <!-- 展示收货信息 -->
      <div class="receiver-info">
        <el-descriptions :column="1" border>
          <el-descriptions-item label="收货人" label-class-name="label-class">{{ rowData.realname }}</el-descriptions-item>
          <el-descriptions-item label="电话" label-class-name="label-class">{{ rowData.mobile }}</el-descriptions-item>
          <el-descriptions-item label="地址" label-class-name="label-class">{{ rowData.address }}</el-descriptions-item>
        </el-descriptions>
      </div>

      <!-- 发货表单 -->
      <el-form :model="form" ref="formRef" :rules="rules" class="ship-form mt-6" label-width="80px">
        <el-form-item label="快递单号" prop="kddh">
          <el-input v-model="form.kddh" placeholder="请输入快递单号" />
        </el-form-item>
        <el-form-item label="快递公司" prop="kdmc">
          <el-input v-model="form.kdmc" placeholder="请输入快递公司" />
        </el-form-item>
        <el-form-item label="备注" prop="mark">
          <el-input v-model="form.mark" type="textarea" placeholder="请输入备注" />
        </el-form-item>
        <el-form-item label="发货人" prop="deliver_name" v-show="false">
          <el-input v-model="form.deliver_name" placeholder="请输入发货人" />
        </el-form-item>
        <el-form-item label="发货人联系电话" prop="deliver_mobile" v-show="false">
          <el-input v-model="form.deliver_mobile" placeholder="请输入发货人联系电话" />
        </el-form-item>
        <el-form-item label="发货地址" prop="deliver_address" v-show="false">
          <el-input v-model="form.deliver_address" placeholder="请输入发货地址" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleConfirm">发货</el-button>
      </template>
    </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import service from '../service'
const dialogVisible = ref(false)

const emits = defineEmits(['getList'])
const form = ref({
  order_id: '',
  kddh: '',
  kdmc: '',
  mark: '',
  deliver_name: '',
  deliver_mobile: '',
  deliver_address: '',
})
const rowData = ref({
  realname: '',
  mobile: '',
  address: ''
})
const formRef = ref(null)
const rules = ref({
  kddh: [
    { required: true, message: '请输入快递单号', trigger: 'blur' }
  ],
  kdmc: [
    { required: true, message: '请输入快递公司', trigger: 'blur' }
  ]
})
const loading = ref(false)

const openDialog = (row) => {
  console.log(row.realname, 'rowData')
  dialogVisible.value = true
  form.value = {
    order_id: row.order_id,
    kddh: '',
    kdmc: '',
    mark: '',
    deliver_name: row.realname || '',
    deliver_mobile: row.mobile || '',
    deliver_address: row.address || ''
  }
  rowData.value.realname = row.realname
  rowData.value.mobile = row.mobile
  rowData.value.address = row.address
}

const handleConfirm = async () => {
  const valid = await formRef.value.validate()
  if (!valid) return
  if (loading.value) return
  loading.value = true
  const res = await service.prizesDeliver({
    ...form.value
  }).finally(() => {
    loading.value = false
  })
  if (!res) return
  dialogVisible.value = false
  ElMessage.success('发货成功')
  emits('getList')
}


defineExpose({
  openDialog
})
</script>

<style>
.label-class {
  width: 80px;
  text-align: right !important;
}
</style>