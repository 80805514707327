<template>
  <div>
    <div style="display: flex; flex-wrap: wrap">
      <div v-for="(item, index) in imageList" :key="index">
        <div style="position: relative" class="imgBox">
          <el-image
            v-if="item"
            :class="`imgStyle ${size} object-cover`"
            :style="`width: ${width};`"
            :src="`${item}?x-oss-process=image/resize,h_150,m_lfit`"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :fit="props.fit"
          />
          <!-- 遮罩 -->
          <div :class="`overlay ${size}`" :style="`width: ${width};`">
            <el-icon style="margin-right: 15px" @click="handleSearchImg(index)">
              <Search />
            </el-icon>
            <el-icon @click="handleDeleteImg(index)">
              <Delete />
            </el-icon>
          </div>
        </div>
      </div>
      <!-- 判断是金刚加的 -->
      <el-upload
        v-if="imageList.length < limit"
        :class="`avatar-uploader ${size}`"
        :style="`width: ${width};`"
        :data="data"
        :action="`${baseurl}/api/index/upload`"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :on-progress="handleAvatarProgress"
        accept="image/*"
        :before-upload="beforeUpload"
      >
        <div :class="`progress ${size}`" v-if="progressFlag">
          <el-progress type="circle" :percentage="progressNum" :width="progressSize" />
        </div>
        <el-icon v-else class="avatar-uploader-icon">
          <Plus />
        </el-icon>
      </el-upload>
    </div>

    <div class="img-viewer-box">
      <el-image-viewer
        v-if="dialogVisible"
        :url-list="imageList"
        :initial-index="imgListIndex"
        @close="close"
      >
      </el-image-viewer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Plus, Search, Delete } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'

interface Props {
  modelValue: string;
  data?: Record<string, any>;
  size?: 'small' | 'default' | 'large';
  limit?: number;
  fit?: string;
  width?: string;
  imgWidth?: number;
  aspectRatio?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  data: () => ({}),
  size: 'small',
  limit: 99,
  fit: 'fill',
  width: '',
  imgWidth: 0,
  aspectRatio: '1:1',
  fileSize: 4
})

const emits = defineEmits(['update:modelValue'])

const imageList = computed(() => {
  return props.modelValue ? props.modelValue.split(',') : []
})

const size = computed(() => {
  return props.size == 'large'
    ? 'large'
    : props.size == 'default'
      ? 'default'
      : props.size == 'small'
        ? 'small'
        : ''
})

const progressSize = computed(() => {
  return props.size == 'large'
    ? 190
    : props.size == 'default'
      ? 140
      : props.size == ''
        ? 140
        : props.size == 'small'
          ? 90
          : ''
})

const progressNum = ref(0)
const progressFlag = ref(false)
const dialogVisible = ref(false)
const imgListIndex = ref(0)

interface UploadProgressEvent {
  percent: number;
}

interface UploadResponse {
  data?: {
    url: string;
  };
  msg?: string;
}

const handleAvatarProgress = (evt: UploadProgressEvent) => {
  progressFlag.value = true
  progressNum.value = parseInt(evt.percent.toString())
  if (evt.percent == 100) {
    progressFlag.value = false
  }
}

const handleAvatarSuccess = (res: UploadResponse) => {
  if (imageList.value.length >= props.limit) return
  
  if(!res.data) {
    ElMessage({
      message: res.msg || '上传失败',
      type: 'warning'
    })
    return
  }
  
  imageList.value.push(res.data.url)
  emits('update:modelValue', imageList.value.join(','))
}

interface ImageSize {
  width: number;
  height: number;
}

const parseAspectRatio = (ratio: string): { width: number; height: number } => {
  const [width, height] = ratio.split(':').map(Number)
  return { width, height }
}

const beforeUpload = async (file: File) => {
//   console.log(props.fileSize, 'fileSize')
  // 1. 验证文件大小（4MB限制）
  const isLt4M = file.size / 1024 / 1024 < 0.5
  if (!isLt4M) {
    ElMessage({
      message: `上传图片大小不能超过 0.5MB !`,
      type: 'warning'
    })
    return false
  }

  // 2. 验证图片尺寸
  const imgSize = await beforeUploadSize(file)
  
  // 验证宽高比
  const { width: ratioWidth, height: ratioHeight } = parseAspectRatio(props.aspectRatio)
  const tolerance = 0.02 // 允许2%的误差
  const actualRatio = imgSize.width / imgSize.height
  const expectedRatio = ratioWidth / ratioHeight
  const isValidRatio = Math.abs(actualRatio - expectedRatio) <= tolerance

  if (!isValidRatio) {
    ElMessage({
      message: `请上传${props.aspectRatio}比例的图片`,
      type: 'warning'
    })
    return false
  }

  // 3. 如果设置了最大宽度限制
  if(props.imgWidth && (imgSize.width > props.imgWidth || imgSize.height > props.imgWidth)) {
    ElMessage({
      message: '上传图片宽度不能超过 ' + props.imgWidth + 'px !',
      type: 'warning'
    })
    return false
  }

  return true
}

const beforeUploadSize = (file: File): Promise<ImageSize> => {
  return new Promise((resolve) => {
    const _URL = window.URL || window.webkitURL
    const img = new Image()
    img.src = _URL.createObjectURL(file)
    img.onload = function () {
      resolve({
        width: img.width,
        height: img.height
      })
    }
  })
}

const handleSearchImg = (index: number) => {
  imgListIndex.value = index
  dialogVisible.value = true
}

const close = () => {
  dialogVisible.value = false
}

const handleDeleteImg = (index: number) => {
  ElMessageBox.confirm('是否要删除此照片?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    imageList.value.splice(index, 1)
    emits('update:modelValue', imageList.value.join(','))
  })
}
</script>

<style scoped>
::v-deep .ep-upload {
  width: 100px;
  height: 100px;
}

.avatar-uploader {
  width: 100px;
  height: 100px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  width: 100px;
  height: 100px;
  font-size: 30px;
  color: #ccc;
}

.avatar-uploader:hover {
  border: 1px dashed #409eff;
}

.progress {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgStyle {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.imgBox {
  margin-right: 10px;
}

.imgBox:hover .overlay {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

/* 不同的尺寸 */
.large {
  width: 200px;
  height: 200px;
}

.default {
  width: 150px;
  height: 150px;
}

.small {
  width: 100px;
  height: 100px;
}
</style> 