<template>
    <el-dialog v-model="visible" width="500" center>
        <el-space direction="vertical" alignment="center" class="my-8 w-full">
            <span>抱歉您暂无操作权限</span>
            <span>可以通过消息通知中本活动招募信息进行报名或者联系官方客服哦!</span>
        </el-space>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="visible = false">取消</el-button>
                <el-button type="primary" @click="visible = false">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>


<script setup>

import { ref } from 'vue'

const visible = ref(false)


const openDialog = () => {
    visible.value = true
}

defineExpose({
    openDialog
})

</script>