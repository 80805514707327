<script setup lang="ts">
import {nextTick, ref, computed} from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'


  const props = defineProps({
    columns: Array,
    exportName: String
  })


  const visible = ref(false)

  const route = useRoute()

  const exportFormRef = ref(null)

  const columnsFormRef = ref(null)


  const emits = defineEmits(['changeColumns'])


  const columnOptions = ref({
    list: [],
  })


  const openDialog = async () => {
    visible.value = true
    await nextTick()
    // 先判断在缓存中时候有设置过导出字段，如果没有则默认全部勾选，有则根据缓存中的字段勾选
    exportOptions.value.checkList = localStorage.getItem(route.path + 'export') ? JSON.parse(localStorage.getItem(route.path + 'export')).checkList : props.columns.filter(item => !item.isOperation).map(item => item.label)
    exportOptions.value.name = localStorage.getItem(route.path + 'export') ? JSON.parse(localStorage.getItem(route.path + 'export')).name : props.exportName
    columnOptions.value.list = localStorage.getItem(route.path + 'columns') ? JSON.parse(localStorage.getItem(route.path + 'columns')) : props.columns.map(item => item.label)
  }

  const exportOptions = ref({
    name: '',
    checkList: []
  })

  const exportFormRules = {
    name: [
      { required: true, message: '请输入导出文件名', trigger: 'blur' },
    ],
    checkList: [
      {
        type: 'array',
        required: true,
        message: '请勾选导出字段',
        trigger: 'change',
      },
    ],
  }

  const columnsFormRules = {
    list: [
      {
        type: 'array',
        required: true,
        message: '请勾表格列',
        trigger: 'change',
      },
    ]
  }




  const onExportSubmit = async (formEl) => {
    if (!formEl) return
    await formEl.validate(valid => {
      if (valid) {
        localStorage.setItem(route.path + 'export', JSON.stringify(exportOptions.value))
        ElMessage.success('导出设置成功')
      }
    })
  }

  const onColumnsSubmit = async (formEl) => {
    if (!formEl) return
    await formEl.validate(valid => {
      if (valid) {
        localStorage.setItem(route.path + 'columns', JSON.stringify(columnOptions.value.list))
        ElMessage.success('表格设置成功')
        emits('changeColumns', columnOptions.value.list)
      }
    })
  }

  const onResetExport = () => {
    exportOptions.value = {
      name: props.exportName,
      checkList: props.columns.map(item => item.label)
    }
    localStorage.removeItem(route.path + 'export')
    ElMessage.success('重置导出成功')
  }

  const onResetColumns = () => {
    columnOptions.value.list = props.columns.map(item => item.label)
    localStorage.removeItem(route.path + 'columns')
    ElMessage.success('重置表格成功')
    emits('changeColumns', columnOptions.value.list)
  }


  const columnsWithoutOperation = computed(() => {
    return props.columns.filter(item => !item.isOperation)
  })





  defineExpose({
    openDialog
  })

</script>

<template>
  <el-dialog v-model="visible" width="70%" title="设置面板">
    <el-divider content-position="left">导出设置</el-divider>
    <el-form :model="exportOptions" :rules="exportFormRules" ref="exportFormRef">
      <el-form-item label="文件名称" prop="name">
        <el-col :xs="24" :sm="24" :md="18" :lg="10" :xl="10">
          <el-input placeholder="请输入" v-model="exportOptions.name"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="选择字段" prop="checkList">
        <el-checkbox-group v-model="exportOptions.checkList">
          <el-checkbox v-for="(column, index) in columnsWithoutOperation" :key="index" :label="column.label"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onExportSubmit(exportFormRef)">保存</el-button>
        <el-button @click="onResetExport">重置</el-button>
      </el-form-item>
    </el-form>
    <el-divider content-position="left">表格设置</el-divider>
    <el-form :model="columnOptions" :rules="columnsFormRules" ref="columnsFormRef">
      <el-form-item label="选择字段" prop="list">
        <el-checkbox-group v-model="columnOptions.list">
          <el-checkbox v-for="(column, index) in columns" :key="index" :label="column.label"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onColumnsSubmit(columnsFormRef)">保存</el-button>
        <el-button @click="onResetColumns">重置</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<style scoped>

</style>