<template>
  <el-dialog
    title="查看券码"
    v-model="dialogVisible"
    width="400px"
    @close="handleClose"
  >
    <p>优惠券名称：{{ currentVoucherName }}</p>
    <p>券码：{{ currentVoucherCode }}</p>
  </el-dialog>
</template>


<script setup>

import { ref } from 'vue'

const dialogVisible = ref(false)
const currentVoucherName = ref('')
const currentVoucherCode = ref('')

const openDialog = (voucherName, voucherCode) => {
  currentVoucherName.value = voucherName
  currentVoucherCode.value = voucherCode
  dialogVisible.value = true
}

const handleClose = () => {
  dialogVisible.value = false
}

defineExpose({
  openDialog
})
</script>
