<template>
  <el-container>
    <el-card shadow="never">
        <el-form inline :model="form" ref="formRef">
            <el-form-item label="活动名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="活动状态" prop="status">
                <el-select v-model="form.status" placeholder="请选择状态" class="!w-[200px]">
                    <el-option label="未开始" value="1" />
                    <el-option label="进行中" value="2" />
                    <el-option label="已结束" value="3" />
                </el-select>
            </el-form-item>
            <el-form-item label="活动时间">
                <el-date-picker 
                    v-model="form.date" 
                    type="datetimerange" 
                    range-separator="至" 
                    start-placeholder="开始日期" 
                    end-placeholder="结束日期" 
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleSearch">搜索</el-button>
                <el-button @click="handleReset">重置</el-button>
            </el-form-item>
        </el-form>
    </el-card>

    <NbTableV2
        ref="tableRef"
        v-model:page="form.page"
        v-model:pageSize="form.pageSize"
        :loading="loading"
        :data="tableData"
        :total="total"
        :columns="columns"
        :isBottom="false"
        :isTop="false"
        :config="{
            border: true,
        }"
        @getList="getList"
    >
        <!-- 时间列插槽 -->
        <template #time="{ row }">
            <!-- <el-space direction="vertical"> -->
                <p>
                    {{ formatTime(row.start_time) }}
                </p>
                <p>~</p>
                <p>{{ formatTime(row.end_time) }}</p>
            <!-- </el-space> -->
        </template>
        <!-- 基础积分系数列插槽 -->
        <template #basePoints="{ row }">
            <el-space direction="vertical">
                <el-text>支付系数{{ row.pay_coefficient }}</el-text>
                <el-text>优惠系数{{ row.discount_coefficient }}</el-text>
            </el-space>
        </template>
        <!-- 状态列插槽 -->
        <template #status="{ row }">
            <SmallDotV2 :type="row.status === 1 ? 'primary' : row.status === 2 ? 'success' : 'danger'" >
                {{ row.status === 1 ? '未开始' : row.status === 2 ? '进行中' : '已结束' }}
            </SmallDotV2>
        </template>
        <!-- 操作列插槽 -->
        <template #operate="{ row }">
            <el-button 
                type="primary" 
                link
                @click="handleAddPrize(row)"
            >
                添加翻倍商品
            </el-button>
            <el-button 
                type="primary" 
                link
                @click="handleAddAward(row)"
            >
                添加奖池奖品
            </el-button>
        </template>
    </NbTableV2>
    <DoubleGoods ref="doubleGoodsRef" @refresh="getList" />
    <DenyDialog ref="denyDialogRef" />
  </el-container>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import NbTableV2 from '@/components/table/components/NbTableV2.vue'
import SmallDotV2 from '@/components/table/components/SmallDotV2.vue'
import DoubleGoods from './components/DoubleGoods.vue'
import DenyDialog from './components/DenyDialog.vue'
import service from './service'
import moment from 'moment'
const router = useRouter()
const form = ref({
    name: '',
    date: [],
    status: '',
    page: 1,
    pageSize: 20
})

const denyDialogRef = ref(null)

const tableData = ref([])
const loading = ref(false)
const total = ref(0)
const doubleGoodsRef = ref(null)
// 表格列配置
const columns = [
    {
        prop: 'name',
        label: '活动名称',
        align: 'left',
        minWidth: '150',
        headerAlign: 'center'
    },
    {
        label: '活动时间',
        align: 'center',
        minWidth: '200',
        slot: 'time',
        headerAlign: 'center'
    },
    {
        label: '基础积分系数',
        align: 'right',
        minWidth: '120',
        slot: 'basePoints',
        headerAlign: 'center'
    },
    {
        label: '活动状态',
        align: 'center',
        minWidth: '100',
        slot: 'status',
        headerAlign: 'center'
    },
    {
        prop: 'total_points_use',
        label: '用户在商家累计积分',
        align: 'right',
        minWidth: '150',
        headerAlign: 'center'
    },
    {
        prop: 'total_points_get',
        label: '用户在商家消耗积分',
        align: 'right',
        minWidth: '150',
        headerAlign: 'center'
    },
    {
        prop: 'prize_num',
        label: '奖池奖品总数量',
        align: 'right',
        minWidth: '120',
        headerAlign: 'center'
    },
    {
        prop: 'over_prize_num',
        label: '已兑换奖品数量',
        align: 'right',
        minWidth: '120',
        headerAlign: 'center'
    },
    {
        prop: 'user_num',
        label: '兑换用户数',
        align: 'right',
        minWidth: '120',
        headerAlign: 'center'
    },
    {
        prop: 'operate',
        label: '操作',
        align: 'right',
        minWidth: '170',
        slot: 'operate',
        headerAlign: 'center',
        fixed: 'right'
    }
]

const formatTime = (time) => {
    return moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
}

// 搜索
const handleSearch = () => {
    form.value.page = 1
    getList()
}

// 重置
const handleReset = () => {
    form.value = {
        name: '',
        date: [],
        status: '',
        page: 1,
        pageSize: 20
    }
    getList()
}

// 获取列表数据
const getList = async () => {
    loading.value = true
    const params = {
        name: form.value.name,
        status: form.value.status,
        page: form.value.page,
        pageSize: form.value.pageSize,
        start_time: form.value.date[0] ? moment(form.value.date[0]).format('YYYY-MM-DD HH:mm:ss') : '',
        end_time: form.value.date[1] ? moment(form.value.date[1]).format('YYYY-MM-DD HH:mm:ss') : ''
    }
    service.getActivityList(params)
        .then(res => {
            console.log(res)
            if(!res) return
            tableData.value = res.data
            total.value = res.total
        })
        .catch(error => {
            console.error(error)
        })
        .finally(() => {
            loading.value = false
        })
}

// 添加翻倍商品
const handleAddPrize = (row) => {
    // TODO: 实现添加翻倍商品逻辑
    if (row.is_bind !== 1) {
        denyDialogRef.value.openDialog()
        return
    }
    // TODO: 实现添加翻倍商品逻辑
    doubleGoodsRef.value.openDialog(row.id)
}

// 添加奖池奖品
const handleAddAward = (row) => {
    // is_bind 1 表示绑定活动 2 表示未绑定活动 未绑定出现弹窗
    if (row.is_bind !== 1) {
        denyDialogRef.value.openDialog()
        return
    }

    router.push({
        path: '/springFestival/prizesList',
        query: {
            activity_id: row.id,
            status: row.status
        }
    })
}

onMounted(() => {
    getList()
})
</script>

<style scoped>
.el-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
</style>

