<template>
  <el-container direction="vertical">
    <el-card shadow="never">
        <el-form inline :model="form" ref="formRef">
            <el-form-item label="游戏号" prop="id_number">
                <el-input v-model="form.id_number" placeholder="请输入ID" />
            </el-form-item>
            <el-form-item label="昵称">
                <el-input v-model="form.nickname" placeholder="请输入昵称" />
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.status" placeholder="请选择状态" class="!w-[200px]">
                    <el-option label="全部" value="" />
                    <el-option
                      v-for="option in prizeStatusOptions"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="奖品类型">
                <el-select v-model="form.prizes_type" placeholder="请选择奖品类型" class="!w-[200px]">
                    <el-option label="全部" value="" />
                    <el-option label="实物" :value="1" />
                    <el-option label="优惠券" :value="5" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleSearch">搜索</el-button>
                <el-button @click="handleReset">重置</el-button>
            </el-form-item>
        </el-form>
    </el-card>
    <nb-table
        v-model:page="form.page"
        v-model:pageSize="form.pageSize"
        :data="tableData"
        :total="total"
        :loading="loading"
        :columns="columns"
        :selection-filter-fn="selectionFilterFn"
        class="mt-3"
        @getList="getList"
        :getExportData="getExportData"
    >
        <template #prizesType="{ row }">
            <small-dot-v2 :type="row.prizes_type === 1 ? 'success' : 'primary'" >{{ row.prizes_type === 1 ? '实物' : '优惠券' }}</small-dot-v2>
        </template>
        <template #createdAt="{ row }">
            {{ formatDate(row.createtime) }}
        </template>
        <template #status="{ row }">
            <small-dot-v2 :type="getPrizeColor(row.status)" >{{ getPrizeStatusText(row.status) }}</small-dot-v2>
        </template>
        <template #operate="{ row }">
          <el-button
            v-if="row.voucher_code_no"
            type="text"
            @click="handleViewVoucher(row)"
          >查看券码</el-button>
          <el-button
            v-if="row.prizes_type === 1 && row.status === 2"
            type="text"
            @click="handleShip(row)"
          >去发货</el-button>
          <el-button
            v-if="row.prizes_type === 1 && row.status === 3"
            type="text"
            @click="handleViewLogistics(row)"
          >查看物流</el-button>
        </template>
    </nb-table>
    <CheckoutVoucherCode ref="voucherCodeDialogRef" />
    <CheckTracking ref="trackingDialogRef" />
    <ShippingDialog ref="shippingDialogRef" @getList="getList" />
  </el-container>
</template>


<script setup>
import { reactive, ref, onMounted } from 'vue'
import NbTable from '@/components/table/components/NbTableV3.vue'
import SmallDotV2 from '@/components/table/components/SmallDotV2.vue'
import CheckoutVoucherCode from './components/CheckoutVoucherCode.vue'
import CheckTracking from './components/CheckTracking.vue'
import ShippingDialog from './components/ShippingDialog.vue'
import moment from 'moment'
import service, { getPrizeStatusOptions, getPrizeColor, getPrizeStatusText } from './service'

const prizeStatusOptions = getPrizeStatusOptions()

const voucherCodeDialogRef = ref(null)
const trackingDialogRef = ref(null)
const shippingDialogRef = ref(null)
const form = reactive({
    resource_type: 3,
    id_number: '',
    nickname: '',
    activity_name: '',
    activity_id: '',
    status: '',
    prizes_type: '',
    page: 1,
    pageSize: 10
})


const tableData = ref([])
const total = ref(0)
const loading = ref(false)

const columns = ref([
    {
        label: '订单ID',
        prop: 'order_id',
        align: 'center',
        headerAlign: 'center',
        minWidth: '120'
    },
    {
        label: '活动名称',
        prop: 'activity_name',
        align: 'left',
        headerAlign: 'center',
        minWidth: '150',
    },
    {
        label: '用户ID',
        prop: 'user_id',
        align: 'left',
        headerAlign: 'center',
        minWidth: '120'
    },
    {
        label: '游戏号',
        prop: 'id_number',
        align: 'left',
        headerAlign: 'center',
        minWidth: '120'
    },
    {
        label: '用户昵称',
        prop: 'nickname',
        align: 'left',
        headerAlign: 'center',
        minWidth: '120'
    },
    {
        label: '奖品名称',
        prop: 'prizes_name',
        align: 'left',
        headerAlign: 'center',
        minWidth: '150'
    },
    {
        label: '奖品类型',
        align: 'center',
        headerAlign: 'center',
        minWidth: '100',
        slot: 'prizesType',
        prop: 'prizes_type',
        exportFormatter: (row) => row.prizes_type === 1 ? '实物' : '优惠券'
    },
    {
        label: '获奖时间',
        prop: 'created_at',
        align: 'center',
        headerAlign: 'center',
        minWidth: '160',
        slot: 'createdAt',
        exportFormatter: (row) => formatDate(row.createtime)
    },
    {
        label: '状态',
        prop: 'status',
        align: 'center',
        headerAlign: 'center',
        minWidth: '100',
        slot: 'status',
        exportFormatter: (row) => getPrizeStatusText(row.status)
    },
    {
        label: '操作',
        prop: 'operate',
        align: 'right',
        headerAlign: 'center',
        fixed: 'right',
        minWidth: '120',
        slot: 'operate'
    },
    {
        label: '奖品类型',
        prop: 'prizes_type',
        exportOnly: true,
        exportFormatter: (row) => row.prizes_type === 1 ? '实物' : '优惠券'
    },
    {
        label: '获奖时间',
        prop: 'created_at',
        exportOnly: true,
        exportFormatter: (row) => formatDate(row.createtime)
    },
    {
        label: '状态',
        prop: 'status',
        exportOnly: true,
        exportFormatter: (row) => getPrizeStatusText(row.status)
    },
    {
      label: '收货人名称',
      prop: 'realname',
      exportOnly: true,
    },
    {
      label: '收货人电话',
      prop: 'mobile',
      exportOnly: true,
    },
    {
      label: '收货地址',
      prop: 'address',
      exportOnly: true,
    }
])

const formatDate = (date) => {
    return moment(date * 1000).format('YYYY-MM-DD HH:mm:ss')
}


onMounted(() => {
    getList()
})




const getList = async () => {
    loading.value = true
    const res = await service.getPrizesOrderList(form).finally(() => {
        loading.value = false
    })
    console.log(res, 'prizeDistributed')
    if (!res) return
    tableData.value = res.data
    total.value = res.total
}

const handleSearch = () => {
    form.page = 1
    getList()
}

const handleReset = () => {
    form.id_number = ''
    form.nickname = ''
    form.status = ''
    form.prizes_type = '',
    form.page = 1
    getList()
}


const handleViewVoucher = (row) => {
  voucherCodeDialogRef.value.openDialog(row.prizes_name, row.voucher_code_no)
}

const handleViewLogistics = (row) => {
  trackingDialogRef.value.openDialog(row.order_id)
}


const getExportData = async () => {
  const res = await service.getPrizesOrderList({
    ...form,
    page: 1,
    pageSize: total.value
  })
  return res.data
}

const handleShip = (row) => {
    console.log(row.user_id, 'row')
  shippingDialogRef.value.openDialog(row)
}


</script>