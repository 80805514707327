import { ref, computed, onMounted } from 'vue'
import {
  getPushList,
  getUnreadNum,
  getNoticeDetail,
  getAnnouncementDetail,
  cancel,
  getActivityAnnouncement,
  getActivityAnnouncementDetail
} from '@/model/notice/notice'
export const desText = ref({
  push_count: 0,
  notice_shop_count: 0
})
export const useNotice = () => {
  const search = ref('')
  const loading = ref(false)
  const noticeTotlaList = ref([])
  const listIndex = ref(null)
  const totalNumber = ref(0)
  const activeIndex = ref(0)
  const detail = ref(null)
  const params = ref({
    page_size: 15,
    page: 1,
    title: ''
  })
  const vuePdfRef = ref(null)
  const tabList = ref([
    {
      name: '全部',
      type: ''
    },
    {
      name: '公告',
      type: 5
    },
    {
      name: '通知',
      type: 4
    },
    {
      name: '活动招募',
      type: 99
    }
  ])

  const onTabList = (e, slider, index, type) => {
    console.log(type, 'type', index, 'index')
    if (cancel) {
      cancel()
    }
    // console.log(type, 'type')
    activeIndex.value = index
    noticeType.value = type
    slider.style.left = `${e.offsetLeft}px`
    slider.style.width = `${e.offsetWidth}px`
    params.value.page = 1
    params.value.title = search.value
    noticeTotlaList.value = []
    listIndex.value = null
    if (type) {
      params.value.type = type
    } else {
      delete params.value.type
    }
    if (type !== 99) {
      serverNoticeAndPush()
    } else {
      // 活动招募的列表
      getActivityAnnouncementList()
    }
  }

  onMounted(() => {
    serverUnreadNum()
    // serverNoticeAndPush()
  })

  const getActivityAnnouncementList = async () => {
    loading.value = true
    const res = await getActivityAnnouncement(2).finally(() => {
      loading.value = false
    })
    if (!res) return
    noticeTotlaList.value = res.map(item => {
      return {
        title: '活动招募',
        createtime: item.created_at,
        id: item.activity_id
      }
    })
  }



  const noticeType = ref(1)
  // 获取全部公告列表
  const serverNoticeAndPush = async () => {
    // console.log(123)
    loading.value = true
    const res = await getPushList(params.value).finally(() => {})
    if (res) {
      // console.log(res, 'res')
      noticeTotlaList.value = [...noticeTotlaList.value, ...res.data]
      totalNumber.value = res.total
      loading.value = false
      serverUnreadNum()
    }
    // console.log(345)
  }

  const noMore = computed(() => noticeTotlaList.value.length >= totalNumber.value)
  const disabled = computed(() => loading.value || noMore.value)

  // 未读数
  const serverUnreadNum = async () => {
    const res = await getUnreadNum()
    // console.log(res)
    if (res) {
      desText.value = res
      tabList.value.forEach((item, index) => {
        if (index === 1) {
          item.notice_shop_count = res.notice_shop_count
        }
        if (index === 2) {
          item.push_count = res.push_count
        }
      })
    }
  }
  const load = () => {
    if (loading.value) return
    params.value.page++
    serverNoticeAndPush()
  }

  // 系统通知详情
  const delLoading = ref(false)
  const handelDetail = async (id, type, is_read, resource_id, index) => {
    listIndex.value = index
    if (delLoading.value) return
    delLoading.value = true

    const res =
      type === 4
        ? await getNoticeDetail(id).finally(() => {
            delLoading.value = false
          })
        : await getAnnouncementDetail(resource_id).finally(() => {
            delLoading.value = false
          })
    // console.log(res, 'sdfdsfsdf')
    if (res) {
      detail.value = res
      // console.log(detail.value)
      if (detail.value.pdf_path) {
        detail.value.pdf_path = detail.value.pdf_path.replace('http://', 'https://')
        // console.log(detail.value.pdf_path, 'detail.value.pdf_path')
      }
      if (is_read === 0) {
        // console.log(is_read, 'is_read')
        serverUnreadNum()
        if (type === 4) {
          noticeTotlaList.value[index].is_read = 1
        } else if (type === 5) {
          noticeTotlaList.value[index].is_read = 1
        }
      }
    }
  }

  const handeActivityDetail = async (id, created_at) => {
    
    if (delLoading.value) return
    delLoading.value = true
    const res = await getActivityAnnouncementDetail(id).finally(() => {
      delLoading.value = false
    })
    if (!res) return
    detail.value = res
    detail.value.created_at = created_at
  }

  // 搜索
  let time = null
  const getSearch = () => {
    // console.log(search.value)
    if (time) {
      clearTimeout(time)
    }
    time = setTimeout(() => {
      params.value.title = search.value
      params.value.page = 1
      noticeTotlaList.value = []
      serverNoticeAndPush()
    }, 500)
  }

  //下载
  const onDownload = (pdfName) => {
    // pdf下载
    // const link = document.createElement('a')
    // link.href = url
    // link.download = 'example.pdf'
    // link.target = '_blank'
    // link.click()
    vuePdfRef.value.download(pdfName)
  }

  return {
    search, // 搜索框的值
    tabList,
    onTabList,
    activeIndex,
    disabled,
    totalNumber,
    loading,
    delLoading,
    noMore,
    load,
    desText,
    noticeTotlaList,
    handelDetail,
    detail,
    getSearch,
    onDownload,
    serverUnreadNum,
    listIndex,
    vuePdfRef,
    serverNoticeAndPush,
    handeActivityDetail
  }
}
