<template>
  <el-select
    v-model="selectValue"
    :placeholder="props.placeholder"
    :loading="loading"
    filterable
    remote
    reserve-keyword
    :remote-method="remoteSearch"
    :value-key="props.valueKey"
    @change="handleChange"
    v-bind="$attrs"
  >
    <el-option
      v-for="item in mergedOptions"
      :key="item[props.valueKey]"
      :label="item[props.labelKey]"
      :value="item[props.valueKey]"
    />
  </el-select>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { ElMessage } from 'element-plus'

// Types
interface Props {
  modelValue: string | number | object | Array<any>
  searchFunction: (params: any) => Promise<any>
  extraParams?: Record<string, any>
  paramsHandler?: (query: string, extraParams: Record<string, any>) => Record<string, any>
  resultHandler?: (data: any) => any[]
  valueKey?: string
  labelKey?: string
  placeholder?: string
  loadOnMount?: boolean
  initialQuery?: string
  initialOptions?: any[]
}

// Props
const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  extraParams: () => ({}),
  paramsHandler: (query, extraParams) => ({
    keyword: query,
    ...extraParams
  }),
  resultHandler: (data) => data,
  valueKey: 'value',
  labelKey: 'label',
  placeholder: '请选择',
  loadOnMount: false,
  initialQuery: '',
  initialOptions: () => []
})

// Emits
const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void
  (e: 'change', value: any): void
}>()

// Reactive state
const loading = ref(false)
const remoteOptions = ref<any[]>([])
const selectValue = ref(props.modelValue)

// Computed
const mergedOptions = computed(() => {
  const optionsMap = new Map()
  
  props.initialOptions.forEach(item => {
    optionsMap.set(item[props.valueKey], item)
  })
  
  remoteOptions.value.forEach(item => {
    optionsMap.set(item[props.valueKey], item)
  })
  
  return Array.from(optionsMap.values())
})

// Watch
watch(
  () => props.modelValue,
  (newVal) => {
    selectValue.value = newVal
  },
  { immediate: true }
)

// Methods
const remoteSearch = async (query: string) => {
  if (!query && !props.loadOnMount) {
    remoteOptions.value = []
    return
  }
  
  loading.value = true
  try {
    const params = props.paramsHandler(query, props.extraParams)
    const res = await props.searchFunction(params)
    remoteOptions.value = props.resultHandler(res)
  } catch (error) {
    console.error('远程搜索失败:', error)
    ElMessage.error('搜索失败，请重试')
    remoteOptions.value = []
  } finally {
    loading.value = false
  }
}

const refresh = (query = '') => {
  return remoteSearch(query)
}

defineExpose({
  refresh
})

const handleChange = (val: any) => {
  emit('update:modelValue', val)
  emit('change', val)
}

// Lifecycle
onMounted(() => {
  if (props.loadOnMount) {
    remoteSearch(props.initialQuery)
  }
})
</script>