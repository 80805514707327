<script setup lang="ts">
// 将el-table的属性全部放到一个属性中通过v-bind传递给el-table
// 将data通过属性传递给el-table，不再使用传递请求方法的方式进行异步请求，而是在外部请求数据后传递给el-table，对外会有方法控制表格的刷新
// 会有一个描述表格列的配置数组，用于生成表格列，需要插槽生成表格列的内容，需要使用动态插槽进行处理
import { defineProps, computed, ref, defineExpose, nextTick } from 'vue'
import { Setting } from '@element-plus/icons-vue'
import SettingPanel from '@/components/table/components/settingPanelV2/SettingPanel.vue'
import { onGenerateExcel } from '@/assets/common/generateExcelTool'
import { useRoute } from 'vue-router'

const route = useRoute()

const settingPanelRef = ref(null)

const props = defineProps({
  columns: Array, // 表格列的配置数组
  data: Array, // 表格数据
  config: {
    // el-table的配置
    type: Object,
    default: () => {
      return {}
    }
  },
  // 数据总条数
  total: {
    type: Number,
    default: 0
  },
  // 是否使用多选功能
  isSelection: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  // 实现可以多选的条件的方法
  selectionFilterFn: {
    type: Function,
    default: () => {
      return () => false
    }
  },
  loading: {
    // 表格加载状态
    type: Boolean,
    default: false
  },
  page: {
    // 当前页
    type: Number,
    default: 1
  },
  pageSize: {
    // 每页条数
    type: Number,
    default: 10
  },
  // 导出时所需的方法
  getExportData: {
    type: Function,
    default: () => {
      return () => {}
    }
  },
  // 导出列表的名称
  exportName: {
    type: String,
    default: '导出列表'
  },
  // 是否显示索引
  isIndex: {
    type: Boolean,
    default: true
  },
  // 是否显示底部
  isBottom: {
    type: Boolean,
    default: true
  },
  // 是否显示顶部区域
  isTop: {
    type: Boolean,
    default: true
  },
  // 是否可以单选
  isRadio: {
    type: Boolean,
    default: false
  }
})

const tableRef = ref(null)

const currentRow = ref(null)

const currentHoverRow = ref(null)

const emits = defineEmits([
  'update:page',
  'update:pageSize',
  'getList',
  'getRows',
  'current-change'
])

const columnsSetting = ref(
  localStorage.getItem(route.path + 'columns')
    ? JSON.parse(localStorage.getItem(route.path + 'columns'))
    : props.columns.map((item) => item.label)
)

const columnsFormat = computed(() => {
  return columnsSetting.value.map((item) => {
    return props.columns.find((column) => column.label === item)
  })
})

const exportLoading = ref(false)

// 已选中的行
const selectedRows = ref([])

// 格式化表格列
const formatColumns = computed(() => {
  // 多选列
  const selectionRow = {
    type: 'selection',
    'reserve-selection': true,
    selectable: (row, index) => {
      return props.selectionFilterFn(row, index)
    }
  }
  // 索引列
  const indexRow = {
    type: 'index',
    index: (index) => {
      return (props.page - 1) * props.pageSize + index + 1
    },
    label: '序号',
    width: '80px',
    headerAlign: 'center',
    align: 'center'
  }
  const arr = [...columnsFormat.value]
  props.isIndex && arr.unshift(indexRow)
  props.isSelection && arr.unshift(selectionRow)
  return arr
})

// 处理多选的change事件
const handleSelectionChange = (rows) => {
  selectedRows.value = rows
  emits('getRows', rows)
}

// 接口排序
const handleSortChange = (rows) => {
  emits('sortChange', rows)
}

// 暴露给外部的清空已选中的行的方法
const clearSelection = () => {
  tableRef.value.clearSelection()
}

// 获取当前已选的行
const getSelectedRows = () => {
  return selectedRows.value
}

// 分页改变
const onPageChange = (page) => {
  emits('update:page', page)
  emits('getList')
}
// 每页条数改变
const onPageSizeChange = (pageSize) => {
  emits('update:page', 1)
  emits('update:pageSize', pageSize)
  emits('getList')
}

// 处理导出
const handleExport = async () => {
  if (exportLoading.value) return
  exportLoading.value = true
  const res = await props.getExportData().finally(() => {
    exportLoading.value = false
  })

  const list = localStorage.getItem(route.path + 'export')
    ? JSON.parse(localStorage.getItem(route.path + 'export')).checkList
    : props.columns.map((item) => item.label)

  const headArr = list.map((item) => {
    return props.columns.find((column) => column.label === item).label
  })
  let handleheadArr = headArr.filter((item) => {
    return item !== '操作'
  })
  const dataList = [handleheadArr]
  res.forEach((row) => {
    const arr = []
    // 获取export的缓存，然后根据缓存的数组重新生成可导出的数组
    list.forEach((item) => {
      const index = props.columns.findIndex((column) => column.label === item)
      if (index >= 0) {
        const formatter = props.columns[index].exportFormatter || props.columns[index].formatter
        const value = formatter ? formatter(row) : row[props.columns[index].prop]
        arr.push(value)
      }
    })
    dataList.push(arr)
  })
  onGenerateExcel(
    dataList,
    localStorage.getItem(route.path + 'export')
      ? JSON.parse(localStorage.getItem(route.path + 'export')).name
      : props.exportName
  )
}

// 打开设置面板
const openSettingPanel = () => {
  settingPanelRef.value.openDialog()
}
// 设置面板的更改表格列的方法
const onChangeColumns = (columns) => {
  columnsSetting.value = columns
}

const handleCurrentChange = (row) => {
  currentRow.value = row
}

// 设置当前选择的行
const setCurrentRow = (row) => {
  const localRow = props.data.find((item) => item.id === row.id)
  tableRef.value.setCurrentRow(localRow)
}

// 设置多选列表
const setSelection = (rows = []) => {
  console.log(rows, 'rows select')
  tableRef.value.clearSelection()
  selectedRows.value = []
  nextTick(() => {
    rows.forEach((row) => {
      const localRow = props.data.find((item) => item.id === row.id)
      tableRef.value.toggleRowSelection(localRow || row, true)
    })
  })
}

// 获取当前选中行
const getCurrentRow = () => {
  return currentRow.value
}

const handleCellMouseEnter = (row) => {
  currentHoverRow.value = row
}
const handleCellMouseLeave = (row) => {
  currentHoverRow.value = null
}

defineExpose({
  clearSelection,
  getSelectedRows,
  setCurrentRow,
  getCurrentRow,
  currentRow,
  setSelection,
  currentHoverRow
})
</script>

<template>
  <el-card shadow="never">
    <el-row justify="space-between" v-if="isTop" class="py-2">
      <el-col :span="12"><slot name="startActive"></slot> </el-col>
      <el-col :span="12">
        <el-row justify="end" v-if="isTop">
          <slot name="topActive"></slot>
          <el-button @click="handleExport" :loading="exportLoading">导出</el-button>
          <el-button text :icon="Setting" @click="openSettingPanel"></el-button>
        </el-row>
      </el-col>
    </el-row>

    <el-row justify="start"> </el-row>
    <el-table
      @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave"
      v-bind="config"
      :data="data"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
      ref="tableRef"
      v-loading="loading"
      :highlight-current-row="isRadio"
      :header-cell-style="{
        color: '#555555',
        fontWeight: 400,
        fontSize: '14px'
      }"
      @current-change="handleCurrentChange"
    >
      <el-table-column v-for="column in formatColumns" :key="column.prop" v-bind="column">
        <template v-if="column.slot" v-slot="scope">
          <slot :name="column.slot" v-bind="scope"></slot>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty description="暂无数据" />
      </template>
    </el-table>
    <el-row justify="center" class="bottom-area py-2" v-if="isBottom && total">
      <div class="statistical-area">
        <span class="primary-color" v-if="isSelection">已选{{ selectedRows.length }}条，</span>
        <span class="primary-color">共{{ total }}条</span>
      </div>
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :current-page="page"
        @current-change="onPageChange"
        @size-change="onPageSizeChange"
        layout="sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-row>
    <SettingPanel
      ref="settingPanelRef"
      :columns="columns"
      :export-name="exportName"
      @change-columns="onChangeColumns"
    ></SettingPanel>
  </el-card>
</template>

<style scoped>
.bottom-area {
  position: relative;
  width: 100%;
  background-color: var(--bg-color);
}
.statistical-area {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.primary-color {
  color: var(--el-color-primary);
  font-size: 14px;
}
</style>
