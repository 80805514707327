<template>
    <el-dialog 
        v-model="dialogVisible" 
        :title="!!prizeId ? '编辑奖品' : '新增奖品'" 
        width="600px" 
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <el-form 
            :model="form" 
            label-width="140px" 
            :rules="rules" 
            ref="formRef"
            :disabled="loading"
        >
            <el-form-item label="奖品类型" prop="type">
                <el-select v-model="form.type" placeholder="请选择" class="!w-[200px]" :disabled="!!prizeId">
                    <el-option label="实物" :value="1" />
                    <el-option label="优惠券" :value="5" />
                </el-select>
            </el-form-item>

            <el-form-item :label="form.type == 1 ? '奖品名称' : '优惠券名称'" prop="name">
                <el-input v-model="form.name" placeholder="请输入" maxlength="50" show-word-limit :disabled="!(route.query.status < 2 || (!prizeId && route.query.status >= 2))" />
            </el-form-item>

            <el-form-item label="奖品图片" prop="image" v-if="form.type == 1">
                <el-space direction="vertical" alignment="start" v-if="route.query.status < 2 || (!prizeId && route.query.status >= 2)">
                    <NbImageUpload
                        v-model="form.image"
                        :limit="1"
                        size="small"
                        aspect-ratio="1:1"
                        :fileSize="0.5"
                    />
                    <el-text>图片宽高比为1:1, 图片大小不能超过 500kb</el-text>
                </el-space>
                <el-image style="width: 100px; height: 100px" :src="form.image" :fit="fit" v-else></el-image>
                
            </el-form-item>
            <el-form-item label="使用类型" prop="use_type" v-if="form.type == 5">
                <el-radio-group v-model="form.use_type" :disabled="!!prizeId" @change="handleUseTypeChange">
                    <el-radio :value="1">通用商品</el-radio>
                    <el-radio :value="2">指定商品</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="选择团购" prop="groupon_id" v-if="form.use_type == 2 && form.type == 5">
                <NbSelectWithRemote
                    ref="selectRef"
                    v-model="form.groupon_id"
                    :searchFunction="service.getGrouponList"
                    :resultHandler="resultHandler"
                    value-key="id"
                    label-key="title"
                    class="!w-[400px]"
                    load-on-mount
                    @change="handleTemplateChange"
                    :disabled="!!prizeId"
                />
            </el-form-item>
            <el-form-item label="优惠券类型" prop="voucher_type" v-if="form.type == 5">
                <el-radio-group v-model="form.voucher_type" :disabled="!!prizeId">
                    <el-radio :value="0">满减券</el-radio>
                    <el-radio :value="1">直减券</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-space v-if="form.type == 5">
                <el-form-item label="优惠券单价" prop="voucher_limit" v-if="form.voucher_type == 0">
                    <el-space>
                        <el-text>满</el-text>
                        <el-input-number v-model="form.voucher_limit" :disabled="!!prizeId" :min="0.02" :max="9999999" :step="0.01" :precision="2" controls-position="right" />
                    </el-space>
                    
                </el-form-item>
                <el-form-item :label="form.voucher_type == 1 ? '优惠券单价' : ''" :label-width="form.voucher_type == 1 ? '140px' : '0px'" prop="voucher_price">
                    <el-space>
                        <el-text>减</el-text>
                        <el-input-number v-model="form.voucher_price" :disabled="!!prizeId" :min="0.01" :max="9999999" :step="0.01" :precision="2" controls-position="right" />
                    </el-space>
                </el-form-item>
            </el-space>
            
            <el-form-item label="相对时间" v-if="form.type == 5">
                <el-input-number v-model="form.expired_day" :disabled="!!prizeId" :min="1" :max="9999" :step="1" :precision="0" :controls="false" />
                <el-text>天</el-text>
            </el-form-item>
            <el-form-item label="奖品数量" prop="num">
                <el-input-number
                    v-if="!prizeId || (prizeId && route.query.status < 2)"
                    v-model="form.num" 
                    :min="1" 
                    :step="1" 
                    :precision="0" 
                    :controls="false"
                />
                <el-text v-else>{{ addNum + form.num }}</el-text>
                <el-button text v-if="route.query.status >= 2 && prizeId" type="primary" @click="handleAddNum">追加</el-button>
            </el-form-item>

            <el-form-item label="参考价值" prop="price">
                <el-input-number 
                    v-model="form.price" 
                    :min="0.01" 
                    :step="0.01" 
                    :precision="2" 
                    :controls="false"
                />
            </el-form-item>

            <el-form-item label="兑换单价" prop="reward.luck_value">
                <el-input-number 
                    v-model="form.reward.luck_value" 
                    :min="0.01" 
                    :step="0.01" 
                    :precision="2" 
                    :controls="false"
                    :disabled="!(route.query.status < 2 || (!prizeId && route.query.status >= 2))"
                />
            </el-form-item>

            <el-form-item label="每人限制兑换次数" prop="reward.exchange_limit_quantity">
                <el-input-number 
                    v-model="form.reward.exchange_limit_quantity" 
                    :min="1" 
                    :step="1" 
                    :precision="0" 
                    :controls="false"
                    :disabled="!(route.query.status < 2 || (!prizeId && route.query.status >= 2))"
                />
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input-number 
                    v-model="form.sort" 
                    :min="1" 
                    :step="1" 
                    :precision="0" 
                    :controls="false"
                />
            </el-form-item>
        </el-form>

        <template #footer>
            <el-button @click="dialogVisible = false" :disabled="loading">取消</el-button>
            <el-button type="primary" @click="handleSubmit" :loading="loading">确定</el-button>
        </template>
        <AppendNum ref="appendNumRef" @change="handleAppendNumChange" />
    </el-dialog>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import NbImageUpload from '@/components/form/NbSquareImageUpload.vue'
import service from '../service'
import NbSelectWithRemote from '@/components/form/NbSelectWithRemote.vue'
import AppendNum from './AppendNum.vue'
const dialogVisible = ref(false)
const appendNumRef = ref(null)
const formRef = ref(null)
const loading = ref(false)
const prizeId = ref('')
const addNum = ref(0)


const route = useRoute()


const form = ref({
    resource_id: '',
    type: 1,
    name: '',
    image: '',
    use_type: 1,
    groupon_id: '',
    voucher_type: 1,
    expired_day: 1,
    num: '',
    voucher_price: '',
    voucher_limit: '',
    price: '',
    sort: 50,
    reward: {
        exchange_limit_quantity: '',
        luck_value: '',

    }
})

const originalNum = ref(0)


const rules = {
    type: [{ required: true, message: '请选择奖品类型', trigger: 'change' }],
    name: [{ required: true, message: '请输入奖品名称', trigger: 'blur' }],
    image: [{ required: true, message: '请上传奖品图片', trigger: 'change' }],
    num: [{ required: true, message: '请输入奖品数量', trigger: 'blur' }],
    use_type: [{ required: true, message: '请选择使用类型', trigger: 'change' }],
    groupon_id: [{ required: true, message: '请选择团购', trigger: 'change' }],
    voucher_type: [{ required: true, message: '请选择优惠券类型', trigger: 'change' }],
    voucher_price: [{ required: true, message: '请输入优惠券单价', trigger: 'blur' }],
    voucher_limit: [
      { required: true, message: '请输入优惠券满减金额', trigger: 'blur' },
      {
        validator: (rule, value, callback) => {
          if (value <= form.value.voucher_price) {
            callback(new Error('满减金额必须大于优惠券面值'))
          } else {
            callback()
          }
        },
        trigger: 'blur'
      }
    ],
    price: [{ required: true, message: '请输入参考价值', trigger: 'blur' }],
    'reward.luck_value': [{ required: true, message: '请输入兑换单价', trigger: 'blur' }],
    'reward.exchange_limit_quantity': [{ required: true, message: '请输入每人限制兑换次数', trigger: 'blur' }],
    sort: [{ required: true, message: '请输入排序', trigger: 'blur' }]
}

const emits = defineEmits(['refresh'])

const handleUseTypeChange = () => {
    form.value.groupon_id = ''
}


const openDialog = (activity_id, prize_id = '') => {
    dialogVisible.value = true
    form.value.image = ''
    form.value.use_type = 1
    form.value.groupon_id = ''
    form.value.voucher_type = 1
    form.value.expired_day = 1
    nextTick(() => {
        formRef.value.resetFields()
        addNum.value = 0
        prizeId.value = ''
        form.value.resource_id = activity_id
        if (prize_id) {
            getDetail(prize_id)
        }
    })
}

const getDetail = async (prize_id) => {
    loading.value = true
    const res = await service.getPrizesDetail({prize_id}).finally(() => {
        loading.value = false
    })
    console.log(res, 'res')
    prizeId.value = prize_id
    form.value.type = res.data.type
    form.value.name = res.data.name
    form.value.image = res.data.image
    form.value.use_type = res.data.use_type
    form.value.groupon_id = Number(res.data.groupon_id)
    form.value.use_type = Number(res.data.groupon_id) ? 2 : 1
    form.value.voucher_type = res.data.voucher_type
    form.value.voucher_price = res.data.voucher_price
    form.value.voucher_limit = res.data.voucher_limit
    form.value.expired_day = res.data.expired_day
    form.value.num = res.data.num
    originalNum.value = res.data.num
    form.value.price = res.data.price
    form.value.sort = res.data.sort
    form.value.reward.luck_value = res.data.luck_value
    form.value.reward.exchange_limit_quantity = res.data.exchange_limit_quantity
}


const resultHandler = (res) => {
    return res.list.map(item => {
        return {
            id: item.id,
            title: `【${item.identity}】${item.title}`
        }
    })
}

const handleSubmit = async () => {
    const valid = await formRef.value.validate()
    if (!valid) return

    loading.value = true
    try {
        if (prizeId.value) {
            const params = {
                ...form.value,
                prize_id: prizeId.value
            }


            if (originalNum.value < form.value.num) {
                addNum.value = form.value.num - originalNum.value
                params.num = originalNum.value
            }
            const res = await service.updatePrizes(params)
            if (!res) return


            

            if (addNum.value > 0) { // 在有追加情况的时候再请求追加API
                await service.addPrizesNum({
                    prize_id: prizeId.value,
                    num: addNum.value
                })
                emits('refresh')
            }

            ElMessage.success('操作成功')
            dialogVisible.value = false
            emits('refresh')
        } else {
            const addRes = await service.addPrizes(form.value)
            if (!addRes) return
            ElMessage.success('操作成功')
            dialogVisible.value = false
            emits('refresh')
        }

    } catch (error) {
        console.error(error)
    } finally {
        loading.value = false
    }
}

const handleAddNum = () => {
    appendNumRef.value.openDialog(prizeId.value)
}

const handleAppendNumChange = (num) => {
    addNum.value = num
}

defineExpose({
    openDialog
})
</script>
