import instance from '@/axios'
import axios from 'axios'
let CancelToken = axios.CancelToken
export let cancel = null
// 通知列表
export const getPushList = (params) => {
  return instance.post('shop/wanlshop/push/all', params, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    })
  })
}
// 未读数
export const getUnreadNum = () => {
  return instance.post('shop/wanlshop/push/notReadCount')
}
// 系统消息详情
export const getNoticeDetail = (id) => {
  return instance.post('shop/wanlshop/push/show', { id })
}

// 公告详情
export const getAnnouncementDetail = (id) => {
  return instance.post('shop/wanlshop/notice_shop/show', { id })
}

// 强制未读列表
export const notReadForciblyList = () => {
  return instance.post('shop/wanlshop/notice_shop/notReadForciblyList')
}

// 一键已读
export const allRead = (id) => {
  return instance.post('shop/wanlshop/push/allRead', { id })
}
// 活动报名审核结果弹窗
export const getActivityAuditResult = () => {
  return instance.post('shop/activity/pop/getEnrollPopList')
}
// 审核结果通知弹窗确认
export const confirmAuditResult = (id) => {
  return instance.post('shop/activity/pop/enrollPopConfirm', { id })
}
// 活动报名公告
export const getActivityAnnouncement = (type) => {
  return instance.post('shop/activity/pop/getActivityReportPopList', { type })
}
// 活动报名公告确认 确认之后就不在弹窗列表中展示
export const confirmActivityAnnouncement = (id) => {
  return instance.post('shop/activity/pop/activityReportConfirm', { id })
}
// 删除活动公告
export const deleteActivityAnnouncement = (id) => {
  return instance.post('shop/activity/pop/reportDel', { id })
}
// 活动报名 进行报名的表单提交
export const enrollActivity = (params) => {
  return instance.post('shop/activity/pop/enroll', params)
}
// 活动招募详情
export const getActivityAnnouncementDetail = (id) => {
  return instance.post('/shop/activity/pop/detail', { id })
}
