import instance from '@/axios'

// 奖品发放状态常量
export const PRIZE_STATUS = {
  RECEIVED: 1,    // 已领取
  PENDING: 2,     // 待发货
  SHIPPED: 3,     // 已发货
  DELIVERED: 4,   // 已收货
  FAILED: 5       // 发放失败
}
// 对应奖品的颜色
export const PRIZE_COLOR = {
  [PRIZE_STATUS.RECEIVED]: 'success',
  [PRIZE_STATUS.PENDING]: 'primary',
  [PRIZE_STATUS.SHIPPED]: 'warning',
  [PRIZE_STATUS.DELIVERED]: 'success',
  [PRIZE_STATUS.FAILED]: 'danger'
}

// 获取奖品状态文本
export const getPrizeStatusText = (status) => {
  const statusMap = {
    [PRIZE_STATUS.RECEIVED]: '已领取',
    [PRIZE_STATUS.PENDING]: '待发货',
    [PRIZE_STATUS.SHIPPED]: '已发货',
    [PRIZE_STATUS.DELIVERED]: '已收货',
    [PRIZE_STATUS.FAILED]: '发放失败'
  }
  return statusMap[status] || '未知状态'
}

// 获取奖品颜色
export const getPrizeColor = (status) => {
  return PRIZE_COLOR[status] || 'primary'
}

// 获取奖品状态选项列表
export const getPrizeStatusOptions = () => {
  return [
    { label: '已领取', value: PRIZE_STATUS.RECEIVED },
    { label: '待发货', value: PRIZE_STATUS.PENDING },
    { label: '已发货', value: PRIZE_STATUS.SHIPPED },
    { label: '已收货', value: PRIZE_STATUS.DELIVERED },
    { label: '发放失败', value: PRIZE_STATUS.FAILED }
  ]
}

export default {
  // 商家活动列表
  getActivityList(data) {
    return instance.post('/shop/activity/Spring2025/activityList', data)
  },

  // 添加翻倍商品详情页
  addActivityShopGrouponDetail(activity_id) {
    return instance.post('/shop/activity/Spring2025/activityShopGrouponDetail', { activity_id })
  },

  // 修改翻倍奖品
  updateActivityShopGroupon(data) {
    return instance.post('/shop/activity/Spring2025/activityShopGrouponUpdate', data)
  },

  // 添加奖品
  addPrizes(data) {
    return instance.post('/shop/activity/Spring2025/addPrizes', data)
  },

  // 编辑奖品
  updatePrizes(data) {
    return instance.post('/shop/activity/Spring2025/updatePrizes', data)
  },

  // 商家奖品列表
  getPrizesList(data) {
    return instance.post('/shop/activity/Spring2025/getPrizesList', data)
  },

  // 删除奖品
  deletePrizes(data) {
    return instance.post('/shop/activity/Spring2025/deletePrizes', data)
  },

  // 追加数量
  addPrizesNum(data) {
    return instance.post('/shop/activity/Spring2025/addPrizesNum', data)
  },

  // 奖品详情
  getPrizesDetail(data) {
    return instance.post('/shop/activity/Spring2025/getPrizesDetail', data)
  },

  // 批量删除奖品
  batchDeletePrizes(data) {
    return instance.post('/shop/activity/Spring2025/batchDelete', data)
  },

  // 商家后台活动奖品订单列表
  getPrizesOrderList(data) {
    return instance.post('/shop/activity/Spring2025/prizesOrderList', data)
  },

  // 奖品发货
  prizesDeliver(data) {
    return instance.post('/shop/activity/Spring2025/prizesDeliver', data)
  },

  // 查询物流
  getPrizesDeliverExpress(data) {
    return instance.post('/shop/activity/Spring2025/prizesDeliverExpress', data)
  },

  // 批量发货
  batchPrizesDeliver(data) {
    return instance.post('/shop/activity/Spring2025/prizesDeliverBatch', data)
  },

  // 获取所有商品
  getGrouponAll(title) {
    return instance.post('/shop/Wanlshop/Groupon/index', {
        page: 1,
        pageSize: 50,
        title,
        status: 1
    })
  },
  // 获取团购列表
  async getGrouponList(query) {
    console.log(query, 'query')
    const res = await instance.post('/shop/wanlshop/voucher/getGrouponList', {
      page: 1,
      pageSize: 500,
      title: query.keyword,
      status: 1
    })
    return res
  },
   // 获取团购列表
   async getGrouponListForDoubleGoods(query) {
    console.log(query, 'queryDoubleGoods')
    const res = await instance.post('/shop/wanlshop/voucher/getGrouponList', {
      page: 1,
      pageSize: 500,
      title: query,
      status: 1
    })
    console.log(res, 'resDoubleGoods')
    return res
  }
}
