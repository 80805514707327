<template>
    <el-dialog v-model="dialogVisible" title="添加翻倍商品" width="500px" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form :model="form" label-width="110px" :rules="rules" ref="formRef" :disabled="loading">
            <el-form-item label="选择商品:" prop="groupon_ids">
                <el-select
                    v-model="form.groupon_ids"
                    multiple
                    filterable
                    remote
                    reserve-keyword
                    :multiple-limit="2"
                    placeholder="请选择商品"
                    :remote-method="getGrouponAdd"
                >
                    <el-option v-for="item in grouponList" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="翻倍系数默认:">
                <el-text>{{ rate }}</el-text>
            </el-form-item>
        </el-form>
        <el-space direction="vertical" class="mt-4" alignment="start">
            <el-text>温馨提示：</el-text>
            <el-text>翻倍商品获得积分=实付金额*基础积分系数*翻倍系数</el-text>
            <el-text>其中用所获得的超出基础积分系数的部分，仅用于在本商家奖池中兑换奖品</el-text>
        </el-space>
        <template #footer>
            <el-button @click="dialogVisible = false" :disabled="loading">取消</el-button>
            <el-button type="primary" @click="handleSubmit" :loading="loading">确定</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { ElMessage } from 'element-plus'
import service from '../service'
import { optionList } from '@/views/couponManagement/excelOptions/couponListExce'


const dialogVisible = ref(false)
const formRef = ref(null)
const form = ref({
    activity_id: '',
    groupon_ids: []
})

const loading = ref(false)

const grouponList = ref([])
const rate = ref(1)

const rules = ref({
    groupon_ids: [
        { required: true, message: '请选择商品', trigger: 'change' }
    ]
})

const emits = defineEmits(['refresh'])



const openDialog = (id) => {
    dialogVisible.value = true
    form.value.activity_id = id
    grouponList.value = []
    getGrouponList()
    nextTick(() => {
        formRef.value.clearValidate()
    })
}

const getGrouponList = async () => {
    loading.value = true
    const res = await service.addActivityShopGrouponDetail(form.value.activity_id).finally(() => {
        loading.value = false
    })
    if (!res) return
    if (!res.data.length) {
        const listRes = await service.getGrouponListForDoubleGoods().finally(() => {
            loading.value = false
        })
        console.log(listRes, 'listRes')
        grouponList.value = listRes.list.map(item => {
            return {
                value: item.id,
                label: `【${item.identity}】${item.title}`
            }
        })
    }
    grouponList.value = res.data.map(item => {
        return {
            value: item.id,
            label: `【${item.identity}】${item.title}`
        }
    })
    form.value.groupon_ids = res.data.map(item => item.id)
    rate.value = res.num
}

const getGrouponAdd = async (query) => {
    // if (!query) return
   
    
    const res = await service.getGrouponListForDoubleGoods(query).finally(() => {
        loading.value = false
    })
    console.log(grouponList.value,11);
    
    if (!res) {
        console.log('res')
        grouponList.value = []
        return
    }
    grouponList.value = res.list.map(item => {
        return {
            value: item.id,
            label: `【${item.identity}】${item.title}`
        }
    })
    console.log(2);
    
}


const handleSubmit = async () => {
    const valid = await formRef.value.validate()
    if (!valid) return
    loading.value = true
    const params = {
        activity_id: form.value.activity_id,
        groupon_ids: form.value.groupon_ids.join(',')
    }
    console.log(params, 'params')
    const res = await service.updateActivityShopGroupon(params).finally(() => {
        loading.value = false
    })
    if (!res) return
    dialogVisible.value = false
    ElMessage.success('添加成功')
    emits('refresh')
}



defineExpose({
    openDialog
})

</script>
